import { useForm } from '../../core/domain/Form/useForm';
import { RequiredValidator } from '../../core/validators/Required.validator';
import { MaxLengthValidator } from '../../core/validators/MaxLength.validator';
import { useParams } from 'react-router';
import { ReleaseViewModel } from '../../core/domain/Release/Release.type';
import { EpisodeStore } from '../../stores/episode';
import { useState } from 'react';

function fromEntityToForm(data?: ReleaseViewModel | null): Partial<EpisodeForm> {
	if (!data) {
		return {};
	}

	return {
		title: data.title,
		description: data.description,
		explicit: data.explicit,
		audio: undefined,
		needPublish: data.publishedAt ? null : false,
	};
}

type EpisodeForm = {
	title: string;
	description: string;
	explicit: boolean;
	audio: File;
	needPublish: boolean | null;
};
export const useEpisodeFormScreenBase = () => {
	const { episodeId, podcastId } = useParams();
	const isEditing = !!episodeId;
	const { create, update } = EpisodeStore.useActions();
	const { errorCode } = EpisodeStore.useSelector(isEditing ? 'update' : 'create');

	const { data } = EpisodeStore.useSelector('entity');

	const [audioMode, setAudioMode] = useState<'upload' | 'listen'>(isEditing ? 'listen' : 'upload');

	const { isSubmitting, errors, values, getChangeHandler, submitHandler } = useForm<EpisodeForm>({
		validators: {
			title: [new RequiredValidator()],
			description: [new RequiredValidator(), new MaxLengthValidator(1000)],
			audio: audioMode === 'upload' ? [new RequiredValidator()] : undefined,
		},
		initialState: {
			explicit: false,
			needPublish: false,
			...(isEditing ? fromEntityToForm(data) : {}),
		},
		onSubmit: async (values) => {
			if (podcastId) {
				const audioContext = new AudioContext();
				const buffer = values.audio ? await audioContext.decodeAudioData(await values.audio.arrayBuffer()) : null;
				const needPublish = values.needPublish === null || values.needPublish;

				if (isEditing) {
					await update({
						id: episodeId,
						channelId: podcastId,
						title: values.title,
						description: values.description,
						explicit: values.explicit,
						needPublish,
						...(buffer && audioMode === 'upload' ? { duration: buffer.duration, audio: values.audio } : {}),
					});
				} else if (buffer) {
					await create({ ...values, channelId: podcastId, duration: buffer.duration, needPublish });
				}
			}
		},
	});

	return {
		errors,
		values,
		getChangeHandler,
		submitHandler,
		errorCode,
		isSubmitting,
		isEditing,
		audioUrl: data?.audioUrl,
		setAudioMode,
		audioMode,
	};
};
