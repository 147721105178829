import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { FieldLayout } from '../FieldLayout';
import { Button, File as FileButton, Image, ImageBaseSize, Text } from '@vkontakte/vkui';
import { Icon24Camera } from '@vkontakte/icons';
import css from './UploadImageField.css';
import { LiteralUnion } from '@vkontakte/vkui/dist/types';
import clsx from 'clsx';

export type UploadImageFieldProps = {
	name?: string;
	className?: string;
	label?: ReactNode;
	error?: ReactNode;
	value?: File;
	valueUrl?: string;
	onChange?: (value: File | undefined) => void;
	required?: boolean;
	imageSize?: LiteralUnion<ImageBaseSize, number>;
};

export const UploadImageField = (props: UploadImageFieldProps) => {
	const { className, error, label, onChange, name, required, value, imageSize = 120, valueUrl } = props;
	const [imageSource, setImageSource] = useState<string | undefined>(valueUrl);

	useEffect(() => {
		if (value) {
			const reader = new FileReader();
			reader.readAsDataURL(value);
			const loadListener = (event: ProgressEvent<FileReader>) => {
				setImageSource(typeof event.target?.result === 'string' ? event.target.result : undefined);
			};
			reader.addEventListener('load', loadListener);

			return () => {
				reader.removeEventListener('load', loadListener);
			};
		} else {
			setImageSource(valueUrl);
		}
	}, [value, valueUrl]);

	const changeHandler = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (event.target.files?.length) {
				onChange?.(event.target.files[0]);
			}
		},
		[onChange],
	);

	const cancelHandler = useCallback(() => {
		setImageSource(valueUrl);
		onChange?.(undefined);
	}, [onChange, valueUrl]);

	return (
		<FieldLayout
			required={required}
			className={clsx(css.root, { [css.root_error]: !!error }, className)}
			error={error}
			label={label}
		>
			<div className={css.container}>
				<Image className={css.image} size={imageSize} src={imageSource}>
					{!imageSource && <Text>Не выбран</Text>}
				</Image>
				<FileButton
					mode="primary"
					accept="image/*"
					name={name}
					onChange={changeHandler}
					before={<Icon24Camera role="presentation" />}
					size="l"
				>
					{imageSource ? 'Изменить' : 'Выбрать файл'}
				</FileButton>
				{!!value && valueUrl && (
					<Button mode="secondary" size="l" onClick={cancelHandler}>
						Отмена
					</Button>
				)}
			</div>
		</FieldLayout>
	);
};
