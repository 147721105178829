import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { FieldLayout } from '../FieldLayout';
import { File as FileButton } from '@vkontakte/vkui';
import { Icon24Music } from '@vkontakte/icons';
import css from './UploadAudioField.css';
import clsx from 'clsx';
import { AudioPlayer } from '../../AudioPlayer';

export type UploadAudioFieldProps = {
	name?: string;
	className?: string;
	label?: ReactNode;
	error?: ReactNode;
	value?: File;
	onChange?: (value: File) => void;
	required?: boolean;
	bottom?: ReactNode;
};

export const UploadAudioField = (props: UploadAudioFieldProps) => {
	const { className, error, label, onChange, name, required, value, bottom } = props;
	const [audioSource, setAudioSource] = useState<string>();

	useEffect(() => {
		if (value) {
			const reader = new FileReader();
			reader.readAsDataURL(value);
			const loadListener = (event: ProgressEvent<FileReader>) => {
				setAudioSource(typeof event.target?.result === 'string' ? event.target.result : undefined);
			};
			reader.addEventListener('load', loadListener);

			return () => {
				reader.removeEventListener('load', loadListener);
			};
		} else {
			setAudioSource(undefined);
		}
	}, [value]);

	const changeHandler = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (event.target.files?.length) {
				onChange?.(event.target.files[0]);
			}
		},
		[onChange],
	);

	return (
		<FieldLayout
			required={required}
			className={clsx(css.root, { [css.root_error]: !!error }, className)}
			error={error}
			label={label}
		>
			<div className={css.container}>
				<AudioPlayer className={css.audioPlayer} src={audioSource} />
				<FileButton
					mode="primary"
					accept="audio/mp3"
					name={name}
					onChange={changeHandler}
					before={<Icon24Music role="presentation" />}
					size="s"
				>
					Выбрать файл
				</FileButton>
			</div>
			{bottom}
		</FieldLayout>
	);
};
