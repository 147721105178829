import { Layout } from '../../components/Layout';
import { Button, FormItem, FormLayout, FormStatus } from '@vkontakte/vkui';
import { InputField } from '../../components/form/InputField';
import { usePodcastFormScreenBase } from './usePodcastFormScreenBase';
import { TextareaField } from '../../components/form/TextareaField';
import { CheckboxField } from '../../components/form/CheckboxField';
import { UploadImageField } from '../../components/form/UploadImageField';
import { SelectField } from '../../components/form/SelectField';

export const PodcastFormScreen = () => {
	const {
		submitHandler,
		getChangeHandler,
		values,
		errors,
		errorCode,
		categoriesErrorCode,
		isCategoriesFetching,
		categories,
		isSubmitting,
		isEditing,
		logoUrl,
	} = usePodcastFormScreenBase();

	return (
		<Layout
			title={isEditing ? 'Редактирование подкаста' : 'Создание подкаста'}
			header={isEditing ? 'Редактирование подкаста' : 'Создание подкаста'}
		>
			<FormLayout onSubmit={submitHandler}>
				{errorCode && <FormStatus mode="error">{errorCode}</FormStatus>}
				<UploadImageField
					required
					name="logo"
					label="Логотип"
					valueUrl={logoUrl ?? undefined}
					value={values.logo}
					error={errors.logo}
					onChange={getChangeHandler('logo')}
				/>
				<InputField
					required
					name="title"
					label="Название"
					value={values.title}
					onChange={getChangeHandler('title')}
					error={errors.title}
					placeholder="Введите название подкаста"
				/>
				<TextareaField
					required
					name="description"
					label="Описание"
					value={values.description}
					error={errors.description}
					onChange={getChangeHandler('description')}
					placeholder="Введите описание подкаста"
				/>
				<SelectField
					label="Категория"
					placeholder="Выберите категорию"
					required
					name="category"
					options={categories ?? []}
					loading={isCategoriesFetching}
					disabled={!!categoriesErrorCode}
					value={values.category}
					error={categoriesErrorCode || errors.category}
					onChange={getChangeHandler('category')}
				/>
				<InputField
					required
					name="link"
					label="Ссылка на домашнюю страницу подкаста"
					value={values.link}
					onChange={getChangeHandler('link')}
					error={errors.link}
					placeholder="https://link-to-your-podcast-homepage.ru"
				/>
				<InputField
					required
					name="owner"
					label="Электронная почта владельца подкаста"
					value={values.owner}
					onChange={getChangeHandler('owner')}
					error={errors.owner}
					placeholder="email@email.com"
				/>
				<InputField
					required
					name="author"
					label="Автор"
					value={values.author}
					onChange={getChangeHandler('author')}
					error={errors.author}
					placeholder="Укажите автора подкаста"
				/>
				<CheckboxField
					label="Этот подкаст содержит контент для взрослых"
					name="explicit"
					value={values.explicit}
					onChange={getChangeHandler('explicit')}
					error={errors.explicit}
				/>
				<FormItem>
					<Button loading={isSubmitting} size="l" type="submit">
						Сохранить
					</Button>
				</FormItem>
			</FormLayout>
		</Layout>
	);
};
