import {
	ReleaseCreateRequestModel,
	ReleaseCreateViewModel,
	ReleaseResponseModel,
	ReleaseUpdateRequestModel,
	ReleaseUpdateViewModel,
	ReleaseViewModel,
} from './Release.type';

const Create = {
	fromViewToRequest(data: ReleaseCreateViewModel): ReleaseCreateRequestModel {
		return {
			description: data.description,
			audio: data.audio,
			title: data.title,
			explicit: data.explicit,
			channelId: data.channelId,
			needPublish: data.needPublish,
			duration: Math.ceil(data.duration),
		};
	},
};

const Update = {
	fromViewToRequest(data: ReleaseUpdateViewModel): ReleaseUpdateRequestModel {
		return {
			id: data.id,
			description: data.description,
			audio: data.audio,
			title: data.title,
			explicit: data.explicit,
			channelId: data.channelId,
			needPublish: data.needPublish,
			duration: data.duration ? Math.ceil(data.duration) : undefined,
		};
	},
};

const Entity = {
	fromResponseToView(data: ReleaseResponseModel): ReleaseViewModel {
		return {
			id: data.id,
			title: data.title,
			audioUrl: data.audioUrl,
			imageUrl: data.imageUrl,
			description: data.description,
			publishedAt: data.publishedAt,
			createdAt: data.createdAt,
			explicit: data.explicit,
			removed: data.removed,
			channelId: data.channelId,
			createdById: data.createdById,
		};
	},
};

export const ReleaseConverter = {
	Create,
	Update,
	Entity,
};
