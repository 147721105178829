import { Layout } from '../../components/Layout';
import { Button, FormItem, FormLayout, FormStatus } from '@vkontakte/vkui';
import { InputField } from '../../components/form/InputField';
import { TextareaField } from '../../components/form/TextareaField';
import { CheckboxField } from '../../components/form/CheckboxField';
import { UploadAudioField } from '../../components/form/UploadAudioField';
import { useEpisodeFormScreenBase } from './useEpisodeFormScreenBase';
import { AudioPlayer } from '../../components/AudioPlayer';
import { FieldLayout } from '../../components/form/FieldLayout';

export const EpisodeFormScreen = () => {
	const {
		submitHandler,
		getChangeHandler,
		values,
		errors,
		errorCode,
		isSubmitting,
		isEditing,
		audioUrl,
		audioMode,
		setAudioMode,
	} = useEpisodeFormScreenBase();

	return (
		<Layout
			title={isEditing ? 'Редактирование выпуска' : 'Создание выпуска'}
			header={isEditing ? 'Редактирование выпуска' : 'Создание выпуска'}
		>
			<FormLayout onSubmit={submitHandler}>
				{errorCode && <FormStatus mode="error">{errorCode}</FormStatus>}
				{audioMode === 'listen' && (
					<FieldLayout required label="Аудио-файл (уже загружен)">
						<AudioPlayer src={audioUrl} />
						<Button mode="link" onClick={() => setAudioMode('upload')}>
							Изменить
						</Button>
					</FieldLayout>
				)}
				{audioMode === 'upload' && (
					<UploadAudioField
						required
						name="audio"
						label="Аудио-файл"
						value={values.audio}
						error={errors.audio}
						onChange={getChangeHandler('audio')}
						bottom={
							isEditing && (
								<Button mode="link" onClick={() => setAudioMode('listen')}>
									Отмена
								</Button>
							)
						}
					/>
				)}
				<InputField
					required
					name="title"
					label="Название"
					value={values.title}
					onChange={getChangeHandler('title')}
					error={errors.title}
					placeholder="Введите название подкаста"
				/>
				<TextareaField
					required
					name="description"
					label="Описание"
					value={values.description}
					error={errors.description}
					onChange={getChangeHandler('description')}
					placeholder="Введите описание подкаста"
				/>
				<CheckboxField
					label="Этот выпуск содержит контент для взрослых"
					name="explicit"
					value={values.explicit}
					onChange={getChangeHandler('explicit')}
					error={errors.explicit}
				/>
				{values.needPublish !== null && (
					<CheckboxField
						label="Опубликовать сразу"
						name="needPublish"
						value={values.needPublish}
						onChange={getChangeHandler('needPublish')}
						error={errors.needPublish}
					/>
				)}
				<FormItem>
					<Button loading={isSubmitting} size="l" type="submit">
						Сохранить
					</Button>
				</FormItem>
			</FormLayout>
		</Layout>
	);
};
