import { useForm } from '../../core/domain/Form/useForm';
import { Option } from '../../core/domain/Option/Option.type';
import { RequiredValidator } from '../../core/validators/Required.validator';
import { MaxLengthValidator } from '../../core/validators/MaxLength.validator';
import { EmailValidator } from '../../core/validators/Email.validator';
import { UserStore } from '../../stores/user';
import { PodcastStore } from '../../stores/podcast';
import { UrlValidator } from '../../core/validators/Url.validator';
import { useParams } from 'react-router';
import { ChannelViewModel } from '../../core/domain/Channel/Channel.type';

function fromEntityToForm(data?: ChannelViewModel | null): Partial<PodcastForm> {
	if (!data) {
		return {};
	}

	return {
		title: data.title,
		description: data.description,
		link: data.link,
		owner: data.owner,
		category: data.category,
		author: data.author,
		explicit: data.explicit,
		logo: undefined,
	};
}

type PodcastForm = {
	title: string;
	description: string;
	link: string;
	owner: string;
	category: Option;
	author: string;
	explicit: boolean;
	logo: File;
};
export const usePodcastFormScreenBase = () => {
	const email = UserStore.useSelector('email');
	const { podcastId } = useParams();
	const isEditing = !!podcastId;
	const { create, update } = PodcastStore.useActions();
	const { errorCode } = PodcastStore.useSelector(isEditing ? 'update' : 'create');
	const {
		isFetching: isCategoriesFetching,
		data: categories,
		errorCode: categoriesErrorCode,
	} = PodcastStore.useSelector('category');

	const { data } = PodcastStore.useSelector('entity');

	const { isSubmitting, errors, values, getChangeHandler, submitHandler } = useForm<PodcastForm>({
		validators: {
			title: [new RequiredValidator()],
			description: [new RequiredValidator(), new MaxLengthValidator(1000)],
			link: [new RequiredValidator(), new UrlValidator()],
			owner: [new RequiredValidator(), new EmailValidator()],
			logo: isEditing ? [] : [new RequiredValidator()],
			category: [new RequiredValidator()],
			author: [new RequiredValidator(), new MaxLengthValidator(255)],
		},
		initialState: {
			owner: isEditing ? data?.owner : email,
			explicit: false,
			...(isEditing ? fromEntityToForm(data) : {}),
		},
		onSubmit: async (values) => {
			if (isEditing) {
				await update({ id: podcastId, ...values });
			} else {
				await create(values);
			}
		},
	});

	return {
		errors,
		values,
		getChangeHandler,
		submitHandler,
		errorCode,
		isCategoriesFetching,
		categories,
		categoriesErrorCode,
		isSubmitting,
		isEditing,
		logoUrl: data?.imageUrl,
	};
};
